.content-wrapper {
  align-items: center;
  background: var(--tertiary);
  display: flex;
  justify-content: center;
  padding: 2rem;
  text-align: center;

  p {
    color: var(--primary);
    font-family: var(--schlange);
    font-size: 1.5rem;
    margin: auto;
    width: 60%;
  }

  @media (max-width: 768px) {
    p {
      width: 100%;
    }
  }

  @media (max-width: 425px) {
    padding: 1rem;

    p {
      font-size: 1rem;
      width: 100%;
    }
  }

  .features {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    margin-top: 2rem;
    text-align: center;

    @media (max-width: 767px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media (max-width: 540px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .box {
      align-items: center;
      display: flex;
      justify-items: center;
      flex-direction: column;

      img {
        width: 3rem;
      }

      p {
        font-size: 1rem;
        margin: 0;
        width: 100%;
      }

      @media (max-width: 425px) {
        img {
          width: 2rem;
        }

        p {
          font-size: 0.7rem;
        }
      }
    }
  }
}
