button {
  background-color: var(--secondary);
  color: var(--white);
  border: 0;
  border-radius: 1rem;
  font-size: 1rem;
  outline: 0;
  text-shadow: 0 0.1rem 0.5rem rgba(24, 24, 24, 0.6);

  &:active {
    border: 0;
  }
}