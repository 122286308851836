.react-multiple-carousel__arrow {
  background-color: var(--tertiary) !important;
  opacity: 0.5 !important;

  &:hover {
    opacity: 0.8 !important;
  }
}

.carousel-container {
  @media (max-width: 425px) {
    .react-multi-carousel-item {
      width: 15rem !important;
    }
  }

  .carousel-box {
    align-items: center;
    background-color: var(--primary);
    border: 0.2rem solid var(--tertiary);
    border-radius: 0.8rem;
    color: var(--tertiary);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 2rem;
    height: 10rem;
    justify-content: center;
    overflow: hidden;
    position: relative;
    margin-right: 1rem;

    &.disabled {
      background-color: none;
      border: 0.2rem dotted var(--tertiary);
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      border-color: var(--secondary);

      .arrow {
        background-color: var(--secondary);
      }
    }

    img {
      height: 4rem;
      margin-right: 1rem;
      width: 4rem;
    }

    .arrow {
      background-color: var(--primary);
      border: 0.2rem solid var(--secondary);
      border-radius: 50%;
      bottom: -2rem;
      padding: 0rem 1rem 1.5rem 0.8rem;
      position: absolute;
      right: -2rem;
    }

    @media (max-width: 464px) {
      font-size: 1.4rem;
      flex-direction: column;

      img:not(.arrow) {
        margin: 0;
        width: 3rem;
      }
    }
  }
}