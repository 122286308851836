.offcanvas {
  background-color: var(--tertiary);
  color: var(--primary);
  font-family: var(--schlange);
  font-size: 1.5rem;

  .offcanvas-header {
    justify-content: end;
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
  }
}
