.catalogue-wrapper {
  padding: 3rem 0;

  @media (max-width: 425px) {
    padding: 1rem 0;
  }

  .text {
    color: var(--tertiary);
    font-family: var(--schlange);
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }

    @media (max-width: 425px) {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
}