.accordion {
  font-family: var(--schlange);
 
  .accordion-item {
    color: var(--tertiary);

    .accordion-button,
    .accordion-button:not(.collapsed) {
      background-color: var(--white);
      color: var(--tertiary);
      font-family: var(--schlange);
      font-size: 1.2rem;
      font-weight: 700;
      text-shadow: none;

      @media (max-width: 425px) {
        font-size: 1rem;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}