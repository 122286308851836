.steps-buy-wrapper {
  background-color: var(--tertiary);
  padding: 3rem 0;

  @media (max-width: 425px) {
    padding: 1rem 0;
  }

  h3 {
    color: var(--primary);
  }

  .steps-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .box {
      align-items: center;
      background-color: var(--primary);
      border-radius: 1rem;
      color: var(--tertiary);
      display: flex;
      flex-direction: column;
      font-family: var(--schlange);
      font-size: 1.2rem;
      justify-content: center;
      padding: 1.5rem;
      text-align: center;

      .number {
        align-items: center;
        background-color: var(--tertiary);
        border-radius: 50%;
        color: var(--primary);
        display: flex;
        font-size: 3.5rem;
        font-weight: bold;
        height: 5rem;
        justify-content: center;
        margin-bottom: 2rem;
        width: 5rem;
      }

      @media (max-width: 425px) {
        font-size: 1rem;

        .number {
          margin-bottom: 1rem;
        }
      }
    }
  }
}