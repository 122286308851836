.burger-btn {
  color: var(--tertiary);
  cursor: pointer;
  font-size: 3.5rem;
  position: fixed;
  right: 1rem;
  z-index: 99;

  @media (max-width: 425px) {
    right: 0.4rem;
  }

  &:hover {
    color: var(--secondary);
  }
}

.close-btn {
  color: var(--secondary) !important;
  cursor: pointer;
  font-size: 2rem;
}

.menu {
  flex-direction: column;
  text-align: left;

  li {
    margin-bottom: 0.8rem;

    a {
      color: var(--white);

      &:hover {
        color: var(--pink);
      }
    }
  }
}

.contact {
  bottom: 1rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  position: absolute;

  i {
    margin-right: 0.2rem;
  }
}

