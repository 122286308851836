.hero-banner {
  background: url('../../assets/img/banner.jpg') var(--tertiary) center center no-repeat;
  background-size: cover;
  height: 650px;
  padding-top: 350px;

  .typing {
    color: var(--primary);
    font-size: 4rem;
    height: 200px;
    line-height: 4rem;
    text-align: center;
    // -webkit-text-stroke: 3px var(--white);
  }

  @media (max-width: 425px) {
    height: 350px;
    padding-top: 190px;

    .typing {
      font-size: 2.5rem;
      height: 150px;
      line-height: 2rem;
    }
  }
}
