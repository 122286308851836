*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  text-size-adjust: none;
  user-select: none;
}

html, body {
  background: url('../../assets/img/bg.png') var(--primary);
  color: var(--secondary);
  font-family: var(--negritapro);
	margin: 0;
  min-height: 100vh;
	padding: 0;
  position: relative;
}

@media (max-width: 425px) {
  html, body {
    background-size: initial;
  }
}

.main {
  height: 100vh;
  width: 100%;
}