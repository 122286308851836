.options-wrapper {
  background-color: var(--primary);
  padding: 3rem 0;

  @media (max-width: 425px) {
    padding: 1rem 0;
  }

  .cards-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .box {
      background-color: var(--primary);
      border-radius: 1rem;
      border: 0.2rem solid var(--secondary);
      display: grid;
      gap: 1rem;
      justify-items: center;
      padding: 1rem;

      img {
        height: 7rem;
      }

      p {
        margin-bottom: 0;;
      }

      .title {
        color: var(--tertiary);
        font-family: var(--schlange);
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
      }

      .subtitle {
        color: var(--tertiary);
        font-family: var(--schlange);
        font-size: 1rem;
        text-align: center;
      }

      @media (max-width: 425px) {
        img {
          height: 5rem;
        }

        .title {
          font-size: 1.2rem;
        }
      }
    }
  }
}