.contact-wrapper {
  margin-bottom: 2rem;
  padding-top: 3rem;

  @media (max-width: 425px) {
    padding-top: 1rem;
  }

  .box-contact {
    background-color: var(--primary);
    border-radius: 1rem;
    box-shadow: 0px 0px 8px rgba(0, 0 , 0, 0.6);
    color: var(--tertiary);
    font-family: var(--schlange);
    font-size: 1.2rem;
    font-weight: 700;
    margin: 1rem auto;
    padding: 2rem;
    text-align: center;
    width: 60%;

    @media (max-width: 425px) {
      font-size: 1rem;
      padding: 1rem;
      width: 100%;
    }

    .data {
      text-align: left;

      a {
        color: var(--tertiary);

        &:hover {
          color: var(--secondary);
        }
      }

      i {
        font-size: 2rem;
        margin-right: 1rem;

        @media (max-width: 425px) {
          font-size: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
