.categories-wrapper {
  padding: 1rem 0 8rem;

  @media (max-width: 425px) {
    padding-bottom: 5rem;
  }

  h5 {
    color: var(--tertiary);
    font-size: 2rem;

    img {
      height: 2rem;
      margin: 0 0.5rem 0.5rem 0;
      width: 2rem;
    }

    @media (max-width: 425px) {
      font-size: 1.5rem;
    }
  }

  .invitations-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .item-box {
      align-items: center;
      background-color: var(--primary);
      border: 0.2rem solid var(--tertiary);
      border-radius: 0.8rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      padding: 0.5rem;
      position: relative;

      img {
        height: 100%;
        width: 100%;
      }

      &:hover {
        border-color: var(--secondary);

        .arrow {
          background-color: var(--secondary);
        }
      }

      .arrow {
        background-color: var(--primary);
        border: 0.2rem solid var(--secondary);
        border-radius: 50%;
        bottom: -2.9rem;
        height: 6rem;
        padding: 0rem 2.2rem 2.4rem 0.5rem;
        position: absolute;
        right: -2.8rem;
        width: 8rem;
      }
    }
  }

}