.header-wrapper {
  align-items: center;
  background-color: var(--primary);
  box-shadow: 0px 0px 8px rgba(0, 0 , 0, 0.6);
  display: flex;
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 1;

  svg {
    height: 65px;
    width: 133px;
  }
}