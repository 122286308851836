.loader {
  align-items: center;
  color: var(--tertiary);
  display: flex;
  flex-direction: column;
  font-family: var(--monster);
  font-size: 1rem;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}