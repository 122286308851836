.package-wrapper {
  background: var(--secondary);
  padding: 3rem 0;

  @media (max-width: 425px) {
    padding: 1rem 0;
  }

  .package-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .box {
      background-color: var(--primary);
      border-radius: 1rem;
      border: 0.2rem solid var(--secondary);
      font-family: var(--schlange);
      overflow: hidden;
      position: relative;

      figcaption {
        background-color: var(--tertiary);
        padding: 0.8rem 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        h6 {
          color: var(--primary);
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      ul {
        color: var(--tertiary);
        list-style: none;
        margin-top: 3rem;
        padding: 0 1rem;

        li {
          border-bottom: 0.5px solid var(--tertiary);
          padding: 0.5rem 0;

          i {
            margin-right: 0.5rem;
          }
        }
      }

      .price {
        color: var(--tertiary);
        font-size: 3rem;
        font-weight: bold;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
  }

  .add-text {
    color: var(--primary);
    font-family: var(--schlange);
    font-size: 1.3rem;
    font-weight: 700;
    margin: 1rem auto 0;
    text-align: center;

    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
}
